
import "./css/styles.css";

// LIBRARIES

// CUSTOM JS FILES 

$(document).ready(function() {

    $(window).scroll(function() {
        if ($(this).scrollTop() > 200) {
            $('#header').addClass('scrolled');
        } else {
            $('#header').removeClass('scrolled');
        }
    });

    if ($(this).scrollTop() > 200) {
        $('#header').addClass('scrolled');
    } else {
        $('#header').removeClass('scrolled');
    }
    
});

// Check if there are any forms on the page
const forms = document.querySelectorAll('.website-form');
if (forms.length > 0) {
    // If forms are found, loop over each form
    forms.forEach(function(form) {
        form.addEventListener('freeform-render-success', function(event) {
            // Prevent the default behavior
            event.preventDefault();

            // Hide the form
            this.style.display = 'none';

            // Retrieve the custom success message from the data attribute
            let customSuccessMessage = this.getAttribute('data-success-message');

            // Fallback to a default message if custom message is not provided
            if (!customSuccessMessage) {
                customSuccessMessage = "We have received your application and a member of our team will be in touch soon.";
            }

            // Define the success message HTML structure
            const successMsg = `
                <div class="text-center">
                    <div class=" w-40 h-40 mx-auto">
                        <div id="lottie-animation-${this.id}"></div>
                    </div>
                    <h2 class="text-2xl mb-4">Thank you</h2>
                    <p>${customSuccessMessage}</p>
                </div>`;

            // Create the success message element
            const successMessage = document.createElement("div");
            successMessage.classList.add("success-banner");
            successMessage.innerHTML = successMsg;

            // Insert the success message before the form
            this.parentNode.insertBefore(successMessage, this);

            // Load Lottie animation for this specific form
            lottie.loadAnimation({
                container: document.getElementById(`lottie-animation-${this.id}`), 
                renderer: 'svg',
                loop: false,
                autoplay: true,
                path: '/json-icons/success.json' 
            });
        });
    });
}